<!--
 * @Descripttion: 
 * @Author: NoYo
 * @Date: 2020-12-17 14:27:31
 * @LastEditTime: 2020-12-17 15:11:23
-->
<template>
  <div class="result h-100">
    <img :src="`${publickPath}static/img/icons/icon-success.png`" />
    <div class="title">信息提交成功</div>
    <div class="tip">您可以在个人中心内查看已提交的信息</div>

    <div class="liFa">
      <div class="li">
        <div class="left">店名</div>
        <div class="right">
          <div class="title1" v-if="spmc">{{ spmc }}</div>
        </div>
      </div>
      <div class="li">
        <div class="left">时间</div>
        <div class="right">
          <div class="title1" v-if="visitorTime">{{ visitorTime }}</div>
        </div>
      </div>
      <div class="li">
        <div class="left">商品</div>
        <div class="right">
          <div class="thisBox" v-if="goodsTypePOList">
            <div
              class="box"
              v-for="(item, index) in goodsTypePOList"
              :key="index"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <u-button
      @click="returnMain()"
      size="large"
      style="
        position: fixed;
        left: 50%;
        transform: translateX(-50%);
        bottom: 2rem;
      "
      type="primary"
      >返回首页</u-button
    >
  </div>
</template>

<script>
import { businessVisitoredit } from "@utils/aRequest";
import UButton from "@/components/u-button";
export default {
  data() {
    return {
      publickPath: process.env.BASE_URL,
      goodsTypePOList: [],
      spmc: "",
      visitorTime: "",
    };
  },
  components: {
    UButton,
  },
  created() {
    let id = this.$route.query.id;
    if (id) {
      this.getInitialization(id);
    }
  },
  methods: {
    getInitialization(id) {
      var self = this;
      businessVisitoredit({ businessVisitorId: id }).then((data) => {
        if (data.code == "SUCCESS") {
          console.log(data);
          self.goodsTypePOList = data.data.goodsTypePOList;
          self.spmc = data.data.spmc;
          self.visitorTime = this.$tools.format(
            data.data.visitorTime,
            "yyyy-MM-dd hh:mm:ss"
          );
        }
      });
    },
    returnMain() {
      this.$router.push("/main");
    },
  },
  mounted() {
    this.$nextTick(() => {});
  },
};
</script>

<style lang="less" scoped>
.result {
  text-align: center;
  position: relative;
  padding: 0 1.0666rem; /* 90/18.75 */
  img {
    height: 6.186667rem /* 116/18.75 */;
    margin-top: 2rem;
  }
  .title {
    margin-top: 1.5rem /* 36/18.75 */;
    font-size: 1.066667rem /* 20/18.75 */;
    font-family: PingFang SC;
    font-weight: 400;
    color: #2e77ee;
  }
  .tip {
    font-size: 0.746667rem /* 14/18.75 */;
    font-family: PingFang SC;
    font-weight: 400;
    color: @fcg;
    margin-top: 0.426667rem /* 8/18.75 */;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e0e0e0;
  }
  .liFa {
    width: 100%;
    .li {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin: 1rem 0;
      .left {
        width: 3rem;
        font-size: 0.8rem;
        text-align: left;
      }
      .right {
        flex: 1;
        .title1 {
          font-size: 0.8rem;
          font-family: PingFang SC;
          font-weight: 400;
          text-align: left;
          color: #333333;
        }
        .thisBox {
          width: 100%;
          .box {
            float: left;
            width: 30%;
            border: 1px solid #888888;
            color: #969696;
            font-size: 0.6rem;
            padding: 0.2rem 0;
            border-radius: 10rem;
            margin-bottom: 0.8rem;
          }
          .box:nth-child(3n-1) {
            margin: 0 5%;
          }
        }
      }
    }
  }
}
</style>